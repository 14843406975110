import React, {useRef, useState} from 'react';
import {useFormik} from 'formik';
import {
    Box,
    Button,
    Textarea,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    VStack,
    Select,
    HStack,
    InputGroup,
    Skeleton,
    InputRightAddon
} from "@chakra-ui/react";
import axiosInstance from "../../Api";
import * as Yup from "yup";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useAuth} from "../Providers/Auth/AuthContext";
import ErrorPage from '../Pages/ErrorPage';
import {getErrorMsg} from '../../utils/funcs';
import ImageUploadFormControl from "../ImageUploadFormControl";

const CouponCreateForm = () => {
    const [genCode, setGenCode] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const queryClient = useQueryClient();
    const {user} = useAuth();
    const retailerId = user.retailer_id;
    const handleSubmit = async () => {
        makeCoupon(formik.values);
    }

    const generateRandomCode = () => {
        makeCode();
    }
    const resetFullForm = () => {
        setIsSent(false)
        formik.resetForm();
    }
    const CouponValidationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required."),
        coupon_type: Yup.string().required("A type is required."),
        value: Yup.number().required("Coupon value is required."),
        code: Yup.string().required("A code is required."),
        file: Yup.mixed().optional('File is optional.'),
        min_purchase_requirement: Yup.number().optional('A minimum purchase amount is optional.'),
    });
    const initialValues = {
        name: "",
        coupon_type: "",
        value: "",
        code: "",
        file: "",
        min_purchase_requirement: ""
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: CouponValidationSchema,
        onSubmit: values => {
            handleSubmit();
        },
    })

    const {mutate: makeCode, isPending: pendingMakeCode, error: codeError} = useMutation({
        mutationFn: (postData) => axiosInstance.post('/me/coupon_code/generate'),
        onSuccess: (resp) => {
            formik.setFieldValue('code', resp.data);
        },
        onError: (e) => {
            // toast.error('Could not submit comment, please try again.');
            console.log(e);
        },
    });
    const {mutate: makeCoupon, isPending: makeCouponLoading, error: couponError} = useMutation({
        mutationFn: (formData) => axiosInstance.post('/me/coupons', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }),
        onSuccess: (resp) => {
            formik.resetForm();
            setIsSent(true);
            queryClient.invalidateQueries(['coupons', retailerId]);
        },
        onError: (e) => {
            // toast.error('Could not submit comment, please try again.');
            console.log(e);
        },
    });


    if (makeCouponLoading) return <Skeleton/>
    if (codeError) return <ErrorPage text={getErrorMsg(codeError)}/>;
    if (couponError) return <ErrorPage text={getErrorMsg(couponError)}/>

    return (
        <Box bg="white" w={800} p={6} mt={4} rounded="md">
            <form onSubmit={formik.handleSubmit} onBlur={() => setIsSent(false)} id="coupon-create-form">
                <VStack spacing={4} align="flex-start">
                    <FormControl isInvalid={!!formik.errors.name && formik.touched.name}>
                        <FormLabel htmlFor="name">Name</FormLabel>
                        <Input
                            id="name"
                            name="name"
                            type="name"
                            variant="filled"
                            placeholder="e.g. $15 Off"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                        <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                    </FormControl>
                    <HStack mt={2}>
                        <FormControl isInvalid={!!formik.errors.coupon_type && formik.touched.coupon_type}>
                            <FormLabel htmlFor="type">Type of Coupon</FormLabel>
                            <Select
                                placeholder="Select option"
                                id="coupon_type"
                                name="coupon_type"
                                variant="filled"
                                onChange={formik.handleChange}
                                value={formik.values.coupon_type}
                                // onChange={(e) => setFieldValue("instruction", e.target.value)}
                            >
                                <option value='percentage'>Percentage</option>
                                <option value='flat'>Flat $ Amt.</option>
                            </Select>
                            <FormErrorMessage>{formik.errors.coupon_type}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!formik.errors.value && formik.touched.value}>
                            <FormLabel htmlFor="value">Amount</FormLabel>

                            <Input
                                id="value"
                                name="value"
                                type="value"
                                variant="filled"
                                placeholder="e.g. 5"
                                onChange={formik.handleChange}
                                value={formik.values.value}
                            />
                            <FormErrorMessage>{formik.errors.value}</FormErrorMessage>
                        </FormControl>
                    </HStack>
                    {/*<HStack mt={2}>*/}
                    <FormControl mt={2} isInvalid={!!formik.errors.code && formik.touched.code}>
                        <FormLabel htmlFor="name">Code</FormLabel>
                        <InputGroup>
                            <Input
                                id="code"
                                name="code"
                                type="code"
                                variant="filled"
                                placeholder="e.g. PT15OFF"
                                onChange={formik.handleChange}
                                value={formik.values.code}
                            />
                            <InputRightAddon>
                                <Button onClick={generateRandomCode}>Generate Random</Button>
                            </InputRightAddon>
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.code}</FormErrorMessage>
                    </FormControl>
                    {isSent &&
                        <Box color={"green"}>
                            <p>Coupon Created!</p>
                        </Box>
                    }
                    <ImageUploadFormControl label_text={"Add an image to your coupon, like a barcode."} formik={formik}/>
                    <FormControl mt={2}
                                 isInvalid={!!formik.errors.min_purchase_requirement && formik.touched.min_purchase_requirement}>
                        <FormLabel htmlFor="name">Min Purchase Requirement</FormLabel>
                            <Input
                                id="min_purchase_requirement"
                                name="min_purchase_requirement"
                                type="min_purchase_requirement"
                                variant="filled"
                                placeholder="e.g. 50"
                                onChange={formik.handleChange}
                                value={formik.values.min_purchase_requirement}
                            />
                        <FormErrorMessage>{formik.errors.min_purchase_requirement}</FormErrorMessage>
                    </FormControl>
                    <HStack>

                        <Button type="submit" width="full">
                            Create Coupon
                        </Button>
                        <Button type="reset" onClick={resetFullForm}>Reset</Button>
                    </HStack>
                </VStack>
            </form>
        </Box>
    )
}

export default CouponCreateForm;